/* eslint-disable @next/next/no-img-element */
import PropTypes from "prop-types";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();
const { publicOptions } = publicRuntimeConfig;
const path = require("path");
import Head from "next/head";

import { withErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/common/ErrorFallback";

/** ========== */

/**
 *
 * @param {{
 * image;
 * src;
 * alt;
 * placeholder;
 * priority;
 * wrapper:{className,style};
 * width:String|Number;
 * heightString|Number;
 * }} props Props for the component
 *
 */
const ratioScreen = {
  sm: 375,
  md: 768,
  lg: 1280,
};
const Img = ({
  image,
  src,
  alt,
  placeholder,
  priority,
  wrapper,
  width,
  height,
  ...props
}) => {
  const srcStatic = src
    ? src.replace(/^\/+/, "")
    : placeholder.replace(/^\/+/, "");
  if (
    image &&
    image.constructor === Object &&
    Object.keys(image).length !== 0
  ) {
    const webpEnable = publicOptions?.options?.acf?.webpEnable;
    if (webpEnable) {
      const webpSeparateFolder =
        publicOptions?.options?.acf?.webpSeparateFolder;
      const defaultPath = "/wp-content/uploads";
      const separatePath = "/wp-content/webp-express/webp-images/uploads";
      const srcSet = [];
      let originalSize = `${image?.sourceUrl}.webp ${image?.mediaDetails?.width}w`;
      originalSize = webpSeparateFolder
        ? originalSize.split(defaultPath).join(separatePath)
        : originalSize;
      srcSet.push(originalSize);
      const sizes = image?.mediaDetails?.sizes || [];
      if (sizes.length > 0) {
        sizes.forEach((size) => {
          let newSize = `${size?.sourceUrl}.webp ${size?.width}w`;
          newSize = webpSeparateFolder
            ? newSize.split(defaultPath).join(separatePath)
            : newSize;
          srcSet.push(newSize);
        });
      }
      return (
        <>
          <div {...wrapper}>
            <picture>
              <source
                type="image/webp"
                srcSet={srcSet.join(", ")}
                sizes={image?.sizes}
                {...props}
              />
              <img
                {...props}
                loading={`${priority ? null : "lazy"}`}
                decoding="async"
                src={image?.sourceUrl}
                srcSet={image?.srcSet}
                sizes={image?.sizes}
                width={image?.mediaDetails?.width}
                height={image?.mediaDetails?.height}
                alt={image?.altText ? image?.altText : alt}
              />
            </picture>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div {...wrapper}>
            <img
              {...props}
              loading={`${priority ? null : "lazy"}`}
              decoding="async"
              src={image?.sourceUrl}
              srcSet={image?.srcSet}
              sizes={image?.sizes}
              width={image?.mediaDetails?.width}
              height={image?.mediaDetails?.height}
              alt={image?.altText ? image?.altText : alt}
            />
          </div>
        </>
      );
    }
  } else {
    const extname = path.extname(srcStatic).toLowerCase();

    const isPng = extname.localeCompare(".png") == 0;
    const isJpeg =
      extname.localeCompare(".jpg") == 0 || extname.localeCompare(".jpeg") == 0;
    if (isPng || isJpeg) {
      const srcSplit = srcStatic.split(".")[0];
      const srcArr = srcSplit.split("/");
      const srcOptimize = `assets/optimize-images/${srcArr[srcArr.length - 1]}`;
      let ratio_sm = ratioScreen.sm / width;
      let ratio_md = ratioScreen.md / width;
      let ratio_lg = ratioScreen.lg / width;

      let sm = {
        width: ratioScreen.sm,
        height: Math.round(ratio_sm * height),
      };
      let md = {
        width: ratioScreen.md,
        height: Math.round(ratio_md * height),
      };
      let lg = {
        width: ratioScreen.lg,
        height: Math.round(ratio_lg * height),
      };

      if (width <= ratioScreen.sm) {
        return (
          <>
            {priority && (
              <Head>
                <link
                  rel="preload"
                  href={`${publicOptions.basePath}/${srcOptimize}-${width}x${height}.webp`}
                  as="image"
                  type="image/webp"
                  media={`(max-width: ${sm.width}px)`}
                />
              </Head>
            )}
            <div {...wrapper}>
              <picture>
                <source
                  type="image/webp"
                  srcSet={`${publicOptions.basePath}/${srcOptimize}-${width}x${height}.webp`}
                  media={`(max-width: ${sm.width}px)`}
                />
                {/* <source
                type="image/png"
                srcSet={`${publicOptions.basePath}/${srcOptimize}-${width}x${height}.webp`}
              /> */}
                <img
                  loading={`${priority ? null : "lazy"}`}
                  decoding="async"
                  srcSet={`${publicOptions.basePath}/${srcStatic}`}
                  alt={alt}
                  width={width}
                  height={height}
                  {...props}
                />
              </picture>
            </div>
          </>
        );
      } else if (width > ratioScreen.sm && width < ratioScreen.md) {
        return (
          <>
            {priority && (
              <Head>
                <link
                  rel="preload"
                  href={`${publicOptions.basePath}/${srcOptimize}-${sm.width}x${sm.height}.webp`}
                  as="image"
                  type="image/webp"
                  media={`(max-width: ${sm.width}px)`}
                />
                <link
                  rel="preload"
                  href={`${publicOptions.basePath}/${srcOptimize}-${width}x${height}.webp`}
                  as="image"
                  type="image/webp"
                  media={`(min-width: ${sm.width + 1}px) and (max-width: ${
                    md.width - 1
                  }px)`}
                />
              </Head>
            )}
            <div {...wrapper}>
              <picture>
                <source
                  type="image/webp"
                  srcSet={`${publicOptions.basePath}/${srcOptimize}-${sm.width}x${sm.height}.webp`}
                  media={`(max-width: ${sm.width}px)`}
                />
                <source
                  type="image/webp"
                  srcSet={`${publicOptions.basePath}/${srcOptimize}-${width}x${height}.webp`}
                  media={`(min-width: ${sm.width + 1}px) and (max-width: ${
                    md.width - 1
                  }px)`}
                />
                <img
                  loading={`${priority ? null : "lazy"}`}
                  decoding="async"
                  srcSet={`${publicOptions.basePath}/${srcStatic}`}
                  alt={alt}
                  width={width}
                  height={height}
                  {...props}
                />
              </picture>
            </div>
          </>
        );
      } else if (width >= 768 && width <= 1279) {
        return (
          <>
            {priority && (
              <Head>
                <link
                  rel="preload"
                  href={`${publicOptions.basePath}/${srcOptimize}-${sm.width}x${sm.height}.webp`}
                  as="image"
                  type="image/webp"
                  media={`(max-width: ${sm.width}px)`}
                />
                <link
                  rel="preload"
                  href={`${publicOptions.basePath}/${srcOptimize}-${md.width}x${md.height}.webp`}
                  as="image"
                  type="image/webp"
                  media={`(min-width: ${sm.width + 1}px) and (max-width: ${
                    md.width - 1
                  }px)`}
                />
                <link
                  rel="preload"
                  href={`${publicOptions.basePath}/${srcOptimize}-${width}x${height}.webp`}
                  as="image"
                  type="image/webp"
                  media={`(min-width: ${md.width}px) and (max-width: ${
                    ratioScreen.lg - 1
                  }px)`}
                />
              </Head>
            )}

            <div {...wrapper}>
              <picture>
                <source
                  type="image/webp"
                  srcSet={`${publicOptions.basePath}/${srcOptimize}-${sm.width}x${sm.height}.webp`}
                  media={`(max-width: ${sm.width}px)`}
                />
                <source
                  type="image/webp"
                  srcSet={`${publicOptions.basePath}/${srcOptimize}-${md.width}x${md.height}.webp`}
                  media={`(min-width: ${sm.width + 1}px) and (max-width: ${
                    md.width - 1
                  }px)`}
                />
                <source
                  type="image/webp"
                  srcSet={`${publicOptions.basePath}/${srcOptimize}-${width}x${height}.webp`}
                  media={`(min-width: ${md.width}px) and (max-width: ${
                    lg.width - 1
                  }px)`}
                />
                <img
                  loading={`${priority ? null : "lazy"}`}
                  decoding="async"
                  srcSet={`${publicOptions.basePath}/${srcStatic}`}
                  alt={alt}
                  width={width}
                  height={height}
                  {...props}
                />
              </picture>
            </div>
          </>
        );
      } else if (width >= 1280) {
        return (
          <>
            {priority && (
              <Head>
                <link
                  rel="preload"
                  href={`${publicOptions.basePath}/${srcOptimize}-${sm.width}x${sm.height}.webp`}
                  as="image"
                  type="image/webp"
                  media={`(max-width: ${sm.width}px)`}
                />
                <link
                  rel="preload"
                  href={`${publicOptions.basePath}/${srcOptimize}-${md.width}x${md.height}.webp`}
                  as="image"
                  type="image/webp"
                  media={`(min-width: ${sm.width + 1}px) and (max-width: ${
                    md.width - 1
                  }px)`}
                />
                <link
                  rel="preload"
                  href={`${publicOptions.basePath}/${srcOptimize}-${lg.width}x${lg.height}.webp`}
                  as="image"
                  type="image/webp"
                  media={`(min-width: ${md.width}px) and (max-width: ${
                    ratioScreen.lg - 1
                  }px)`}
                />
                <link
                  rel="preload"
                  href={`${publicOptions.basePath}/${srcOptimize}-${lg.width}x${lg.height}.webp`}
                  as="image"
                  type="image/webp"
                  media={`min-width: ${lg.width}px`}
                />
              </Head>
            )}
            <div {...wrapper}>
              <picture>
                <source
                  type="image/webp"
                  srcSet={`${publicOptions.basePath}/${srcOptimize}-${sm.width}x${sm.height}.webp`}
                  media={`(max-width: ${sm.width}px)`}
                />
                <source
                  type="image/webp"
                  srcSet={`${publicOptions.basePath}/${srcOptimize}-${md.width}x${md.height}.webp`}
                  media={`(min-width: ${sm.width + 1}px) and (max-width: ${
                    md.width - 1
                  }px)`}
                />
                <source
                  type="image/webp"
                  srcSet={`${publicOptions.basePath}/${srcOptimize}-${lg.width}x${lg.height}.webp`}
                  media={`(min-width: ${md.width}px) and (max-width: ${
                    lg.width - 1
                  }px)`}
                />
                <source
                  type="image/webp"
                  srcSet={`${publicOptions.basePath}/${srcOptimize}-${lg.width}x${lg.height}.webp`}
                  media={`min-width: ${lg.width}px`}
                />
                <img
                  loading={`${priority ? null : "lazy"}`}
                  decoding="async"
                  srcSet={`${publicOptions.basePath}/${srcStatic}`}
                  alt={alt}
                  width={width}
                  height={height}
                  {...props}
                />
              </picture>
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          {priority && (
            <Head>
              <link
                rel="preload"
                as="image"
                href={`${publicOptions.basePath}/${srcStatic}`}
              />
            </Head>
          )}
          <div {...wrapper}>
            <img
              loading={`${priority ? null : "lazy"}`}
              decoding="async"
              src={`${publicOptions.basePath}/${srcStatic}`}
              alt={alt}
              width={width}
              height={height}
              {...props}
            />
          </div>
        </>
      );
    }
  }
  return (
    <>
      {priority && (
        <Head>
          <link
            rel="preload"
            as="image"
            href={`${publicOptions.basePath}/${srcStatic}`}
          />
        </Head>
      )}
      <div {...wrapper}>
        <img
          {...props}
          loading={`${priority ? null : "lazy"}`}
          decoding="async"
          src={`${publicOptions.basePath}/${srcStatic}`}
          alt={alt}
        />
      </div>
    </>
  );
};

Img.propTypes = {
  image: PropTypes.object,
  src: PropTypes.string,
  alt: PropTypes.string,
  placeholder: PropTypes.string,
  priority: PropTypes.bool,
  wrapper: PropTypes.object,
};

Img.defaultProps = {
  image: {},
  src: null,
  alt: "placeholder",
  placeholder: "assets/common/placeholder.jpg",
  priority: false,
  wrapper: {
    className: "inline-block",
  },
};

export default withErrorBoundary(Img, { FallbackComponent: ErrorFallback });

/** ========== */
