import { Dialog, Transition } from "@headlessui/react";
import { ToastContext } from "common/contexts";
import Img from "components/atoms/Img";
import { Fragment, useState } from "react";
import { FaTimes } from "react-icons/fa";
import styles from "./index.module.scss";

import { withErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/common/ErrorFallback";

const content_status = {
  success: {
    title: "Send Success",
    description: "We'll contact you all right away.",
  },
  error: {
    title: "Send Error",
    description: "Please check your network connection and try again",
  },
};
let timer;
function Toast({ children, ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const [sendStatus, setSendStatus] = useState("");
  const [sendContent, setSendContent] = useState("");
  const [bgAlert, setBgAlert] = useState("");
  /* Handle Event */
  const closeModal = () => {
    setIsOpen(false);
  };

  const openAlert = (time, status, content) => {
    if (content) {
      setSendContent(content);
    } else {
      setSendContent(content_status[status]);
    }
    switch (status) {
      case "success": {
        setBgAlert("from-arrivalhub-blue-200 to-arrivalhub-gray-base");
        break;
      }
      case "error": {
        setBgAlert("from-arrivalhub-red-600 to-arrivalhub-gray-base");
        break;
      }
      case "confirm": {
        setBgAlert("from-arrivalhub-blue-200 to-arrivalhub-gray-base");
        break;
      }
      case "custom": {
        setBgAlert(
          sendContent.bgAlert ||
            "from-arrivalhub-blue-200 to-arrivalhub-gray-base"
        );
        break;
      }

      default:
        break;
    }
    setSendStatus(status);
    setIsOpen(true);
    if (time) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        closeModal();
      }, time);
    }
  };

  const RenderContent = ({ children }) => {
    switch (sendStatus) {
      case "success": {
        return (
          <>
            <div className="">
              <Img
                src="/assets/icons/checked.png"
                width={80}
                height={80}
                alt="icon success"
                className="mb-6"
              />
            </div>
            <p className="mb-6 text-2xl font-semibold">{sendContent?.title}</p>
            <p className="text-sm">{sendContent?.description}</p>
          </>
        );
      }
      case "error": {
        return (
          <>
            {/* <ProgressBar timer={timer} color={`bg-red-500`} /> */}
            <Img
              src="/assets/icons/error.png"
              width={80}
              height={80}
              alt="icon error"
              className="mb-6"
            />
            <p className="mb-6 text-2xl font-semibold">{sendContent?.title}</p>
            <p className="text-sm">{sendContent?.description}</p>
          </>
        );
      }
      case "confirm": {
        return (
          <>
            <Img
              src="/assets/icons/warning.png"
              width={98}
              height={100}
              alt="icon error"
              className="mx-auto mb-6"
              wrapper={{
                className: "block",
              }}
            />
            <p className="mb-6 text-2xl font-semibold">{sendContent?.title}</p>
            <div className="flex justify-around">
              <button
                className={
                  "w-28 border border-arrivalhub-pink-300 bg-transparent hover:border-arrivalhub-pink-400 hover:bg-arrivalhub-pink-400"
                }
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                No
              </button>
              <button
                className={"w-28"}
                onClick={() => {
                  sendContent.onClick();
                  setIsOpen(false);
                }}
              >
                Yes
              </button>
            </div>
          </>
        );
      }
      case "custom": {
        return sendContent.element;
      }
      default:
        return (
          <>
            <p className="mb-6 text-2xl font-semibold text-arrivalhub-pink-600">
              {content_status["error"].title}
            </p>
            <p className="text-sm">{content_status["error"].description}</p>
          </>
        );
    }
  };

  /* Effect */

  return (
    <ToastContext.Provider value={{ openAlert, setOpenAlert: setIsOpen }}>
      {children}
      {/* <div className="flex items-center justify-center">
        <button
          type="button"
          onClick={() => {
            openAlert(3000);
          }}
          className="rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          Open dialog
        </button>
      </div> */}

      {isOpen && (
        <Transition appear show={isOpen} as={"div"}>
          <Dialog
            open={isOpen}
            as="div"
            className="fixed inset-0 overflow-y-auto"
            onClose={closeModal}
            style={{ zIndex: 9999 }}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-slate-900 bg-opacity-70" />
              </Transition.Child>

              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div
                  className={`${styles.ToastContent}  relative inline-flex w-full flex-col  justify-center border-2 bg-gradient-to-b px-7  py-28 text-center align-middle transition-all ${bgAlert} rounded-2xl shadow-xl`}
                >
                  <div className={`absolute top-6 right-4 z-10`}>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-full border-2 border-white p-1 text-xs  font-medium text-white transition-all  hover:border-arrivalhub-pink-300 hover:text-arrivalhub-pink-300 focus:outline-none "
                      onClick={closeModal}
                    >
                      <FaTimes size={12} />
                    </button>
                  </div>

                  <div className="relative z-10 overflow-hidden ">
                    <RenderContent />
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      )}
    </ToastContext.Provider>
  );
}

export default withErrorBoundary(Toast, { FallbackComponent: ErrorFallback });
